@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
	@font-face {
		font-family: "Plus Jakarta Sans";
		src: url("/src/assets/fonts/PlusJakartaSans-VariableFont_wght.ttf") format("truetype");
		font-weight: 100 900;
		font-style: normal;
		font-display: swap;
	}

	@font-face {
		font-family: "Plus Jakarta Sans";
		src: url("/src/assets/fonts/PlusJakartaSans-Italic-VariableFont_wght.ttf") format("truetype");
		font-weight: 100 900;
		font-style: italic;
		font-display: swap;
	}

	@font-face {
		font-family: "DM Sans";
		src: url("/src/assets/fonts/DMSans-VariableFont_opsz,wght.ttf") format("truetype");
		font-weight: 100 900;
		font-style: normal;
		font-display: swap;
	}

	@font-face {
		font-family: "DM Sans";
		src: url("/src/assets/fonts/DMSans-Italic-VariableFont_opsz,wght.ttf") format("truetype");
		font-weight: 100 900;
		font-style: italic;
		font-display: swap;
	}

	body {
		@apply font-dm-sans;
	}

	h1,
	h2,
	h3,
	h4,
	h5,
	h6 {
		@apply font-plus-jakarta;
	}
}

:root {
	--background: 0 0% 100%;
	--foreground: 222.2 47.4% 11.2%;

	--muted: 210 40% 96.1%;
	--muted-foreground: 215.4 16.3% 46.9%;

	--popover: 0 0% 100%;
	--popover-foreground: 222.2 47.4% 11.2%;

	--border: 214.3 31.8% 91.4%;
	--input: 214.3 31.8% 91.4%;

	--card: 0 0% 100%;
	--card-foreground: 222.2 47.4% 11.2%;

	--primary: 222.2 47.4% 11.2%;
	--primary-foreground: 210 40% 98%;

	--secondary: 210 40% 96.1%;
	--secondary-foreground: 222.2 47.4% 11.2%;

	--accent: 210 40% 96.1%;
	--accent-foreground: 222.2 47.4% 11.2%;

	--destructive: 0 100% 50%;
	--destructive-foreground: 210 40% 98%;

	--ring: 215 20.2% 65.1%;

	--radius: 0.5rem;
}

.dark {
	--background: 224 71% 4%;
	--foreground: 213 31% 91%;

	--muted: 223 47% 11%;
	--muted-foreground: 215.4 16.3% 56.9%;

	--accent: 216 34% 17%;
	--accent-foreground: 210 40% 98%;

	--popover: 224 71% 4%;
	--popover-foreground: 215 20.2% 65.1%;

	--border: 216 34% 17%;
	--input: 216 34% 17%;

	--card: 224 71% 4%;
	--card-foreground: 213 31% 91%;

	--primary: 210 40% 98%;
	--primary-foreground: 222.2 47.4% 1.2%;

	--secondary: 222.2 47.4% 11.2%;
	--secondary-foreground: 210 40% 98%;

	--destructive: 0 63% 31%;
	--destructive-foreground: 210 40% 98%;

	--ring: 216 34% 17%;

	--radius: 0.5rem;
}

@layer utilities {
	.font-plus-jakarta {
		font-family: "Plus Jakarta Sans", sans-serif;
	}

	.font-dm-sans {
		font-family: "DM Sans", sans-serif;
	}

	.text-xs {
		font-size: 0.75rem;
		line-height: 1rem;
		font-weight: 400;
	}
}

/* Hide scrollbar for Chrome, Safari and Opera */
::-webkit-scrollbar {
	display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
* {
	-ms-overflow-style: none; /* IE and Edge */
	scrollbar-width: none; /* Firefox */
}

/* Optional: If you want to keep the scrollbar for the main page but hide it for specific elements */
.overflow-hidden {
	-ms-overflow-style: none;
	scrollbar-width: none;
}

.overflow-hidden::-webkit-scrollbar {
	display: none;
}

/* Custom scrollbar styling */
.custom-scrollbar::-webkit-scrollbar {
	width: 6px;
	margin-right: 0;
}

.custom-scrollbar::-webkit-scrollbar-track {
	background: transparent;
}

.custom-scrollbar::-webkit-scrollbar-thumb {
	background: #e0e0e0;
	border-radius: 20px;
	border: 0;
}

.custom-scrollbar::-webkit-scrollbar-thumb:hover {
	background: #bdbdbd;
}

/* For Firefox */
.custom-scrollbar {
	scrollbar-width: thin;
	scrollbar-color: #e0e0e0 transparent;
	padding-right: 0 !important;
}
